<template>
	<div class="app-container">
		<el-header class="titles">分类管理</el-header>
		<el-card id="parent" class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>条件查询</span>
			</div>
			<div style="margin-top: 15px">
				<el-input v-model="filterText" placeholder="请输入分类名称"></el-input>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="appendNode()">新增父分类</el-button>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="appendchild()">新增子分类</el-button>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList(page);getquestionlist()"></el-button>
		</el-row>	
		 <!-- default-expand-all -->
		<el-tree :data="data" :props="props" :load="loadNode" node-key="id" :filter-node-method="filterNode" lazy :expand-on-click-node="false" ref="tree">
			<span class="custom-tree-node" slot-scope="{ node, data }">
			        <span>{{ node.label }}</span>
					<div style="margin-left: 20px;width: 400px;" v-for="item in questionname" v-if="data.id === item.id">共{{item.remark}}题</div>
			        <span>
			          <el-button
			            type="text"
			            size="mini"
						icon="el-icon-circle-plus-outline"
			            @click="() => append(node, data)">
			          </el-button>
			          <el-button
			            type="text"
			            size="mini"
						icon="el-icon-edit"
			            @click="() => edit(node, data)">
			          </el-button>
			          <el-button
			            type="text"
			            size="mini"
						icon="el-icon-delete"
			            @click="() => remove(node, data)">
			          </el-button>
			        </span>
			      </span>
		</el-tree>
		<el-dialog :title="titleInfo" :visible.sync="dialogFormVisible">
			<el-form :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
				<el-form-item label="父分类" prop="parentName">
					<el-select style="display: block;" v-model="form.parentName" value-key="id" placeholder="请选择">
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="节点名称" prop="name">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			  <el-button @click="dialogFormVisible = false">取 消</el-button>
			  <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			}
		},
		data() {
			return {
				data: [],
				props: {
					children: 'children',
					label: 'name',
					isLeaf: true
				},
				filterText: '',//查询-名称
				questionname: [],
				titleInfo: '新增子分类',
				dialogFormVisible: false,
				formLabelWidth: '120px',
				form: {
					parentName: '',
					name
				},
				options: [],
				rules: {
					parentName: [{ required: true, message: '请选择父分类', trigger: 'change' }],
					name: [{ required: true, message: '请输入节点名称', trigger: 'blur' }],
				}
			}
		},
		components: {
			
		},
		created(){
			this.getList();
			this.getquestionlist();
		},
		methods:{
			getList() {
				let data = {
					pId: 0
				}
				this.$comjs.ajax.postAjax('/fa/exam/category/tree/list', data, this, res => {
					this.data = res.list;
				});
			},
			loadNode(node, resolve) {
				if (node.level === 0) {
				  return;
				}
				let data = {
					pId: node.data.id
				}
				this.$comjs.ajax.postAjax('/fa/exam/category/parent/list', data, this, res => {
					resolve(res.list);
					// 添加到树状图结构中
					const parent = node.parent;
					const children = parent.data.children || parent.data;
					const index = children.findIndex(d => d.id === node.data.id);
					children[index].children = res.list;
				});
			},
			// 获取分类题目总数量信息
			getquestionlist() {
				this.$comjs.ajax.postAjax('/fa/exam/category/tree/question/list', "", this, res => {
					this.questionname = res.list
				})
			},
			// 条件查询
			filterNode(value, data, node) {
				if (!value) return true;
				//return data.name.indexOf(value) !== -1;
				let parentNote = node.parent
				let labels = [node.label]
				let level = 1
				while (level < node.level) {
					labels = [...labels, parentNote.label]
					parentNote = parentNote.parent
					level++
				}
				return labels.some(label => label.indexOf(value) !== -1)
			},
			// 增加父类节点
			appendNode() {
				console.log(this.data)
				this.$prompt('请输入新增父类节点名称', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消'
				}).then(({ value }) => {
					let datas = {
						categoryName: value,
						pId: 0
					}
					this.$comjs.ajax.postAjax('/fa/exam/category/add', datas, this, res => {
						// 添加
						const newChild = { id: res.data.id, name: value, children: [] };
						this.data.push(newChild);
					});
				}).catch(() => {
				  this.$message({
					type: 'info',
					message: '取消输入'
				  });       
				});
			},
			// 增加子节点
			appendchild() {
				this.options = this.data
				this.dialogFormVisible = true
				
			},
			dialogConfirm(formName) {
				 this.$refs[formName].validate((valid) => {
					 if (valid) {
						 let datas = {
						 	categoryName: this.form.name,
						 	pId: this.form.parentName,
						 }
						 this.$confirm('是否提交数据', '提示', {
						 	confirmButtonText: '确定',
						 	cancelButtonText: '取消',
						 	type: 'warning'
						 }).then(() => {
							 this.$comjs.ajax.postAjax('/fa/exam/category/add', datas, this, res => {
								 this.dialogFormVisible = false
								 this.$refs[formName].resetFields()
								 this.$message({
								 	message: '保存成功！',
								 	type: 'success',
								 	duration: 1000
								 });
								 this.getList()
								 this.getquestionlist()
							 })
						 })
					 }
				 })
			},
			// 增加子节点
			append(node, data) {
				console.log(node,data)
				if(node.level > 1) {
					this.$message({
						message: '只能创建至二级类目！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				this.$prompt('请输入新增节点名称', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消'
				}).then(({ value }) => {
					let datas = {
						categoryName: value,
						pId: node.data.id
					}
					this.$comjs.ajax.postAjax('/fa/exam/category/add', datas, this, res => {
						// 添加节点
						const newChild = { id: res.data.id, name: value, children: [] };
						if (!data.children) {
							this.$set(data, 'children', []);
						}
						data.children.push(newChild);
						// 重新加载当前节点
						node.loaded = false;
						node.expand();
						// 刷新总题目数
						this.getquestionlist()
					});
				}).catch(() => {
				  this.$message({
					type: 'info',
					message: '取消输入'
				  });       
				});
			},
			// 修改
			edit(node, data) {
				this.$prompt('请输入节点名称', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  inputValue: node.data.name
				}).then(({ value }) => {
					let valus = value;
					this.$prompt('请输入排序值', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消'
					}).then(({ value }) => {
						let datas = {
							categoryId: node.data.id,
							categoryName: valus,
							sort: value,
							pId: node.data.parentId ? node.data.parentId : 0
						}
						this.$comjs.ajax.postAjax('/fa/exam/category/edit', datas, this, res => {
							// 修改
							const parent = node.parent;
							const children = parent.data.children || parent.data;
							const index = children.findIndex(d => d.id === data.id);
							children[index].name = valus;
							this.getList();
						});
					}).catch(() => {
					  this.$message({
						type: 'info',
						message: '取消输入'
					  });       
					});
				}).catch(() => {
				  this.$message({
					type: 'info',
					message: '取消输入'
				  });       
				});
			},
			// 删除
			remove(node, data) {
				this.$confirm('是否确定删除', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let remark = 0
					let names = this.questionname 
					for(let i = 0;i < names.length; i++){
						if(data.id == names[i].id){
							remark = names[i].remark
						}
					}
					// if(remark > 0){
					// 	this.$message({
					// 		type: 'warning',
					// 		message: '请将该分类下题目删除或者移动到其他分类下'
					// 	});
					// }
					// else {
						let datas = {
							categoryId: node.data.id,
							deleteChildren: 1
						}
						this.$comjs.ajax.postAjax('/fa/exam/category/delete', datas, this, res => {
							// 删除节点
							const parent = node.parent;
							const children = parent.data.children || parent.data;
							const index = children.findIndex(d => d.id === data.id);
							// children.splice(index, 1);
							
							if(children[0].isParent) {
								this.getList();
							} else {
								//重新加载父节点-刷新
								node.parent.loaded = false;
								node.parent.expand();
							}
						});
					// }
				})
			},
		},
		computed:{
			
		}
	}
</script>

<style lang="scss">
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.el-tree {
		padding: 10px;
		border: 1px solid #E4E7ED;
		border-radius: 4px;
	}
	.el-row {
		padding: 20px 0;
	}
	.custom-tree-node {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.elCard {
		margin-top: 20px;
	}
	#parent {
		position: relative;
	}
	#child {
		position: absolute;
		height: 36px;
		width: 80px;
		margin-top:-18px;
		top: 50%;
		left: 88%;
	}
</style>

